<template>
  <div class="all-topics">
      <BackBar :pageTit="headTit" />
    <div class="topics">
        <div class="topic" v-for="_ in topics" :key="'topic'+_.id" @click="go(_.id)">
            <div><img src="~@/assets/img/li-ico.png">{{_.title}}</div>
            <div>
                <span>{{_.post_count}}</span>
                <i></i>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import BackBar from '@/components/BackBar.vue'
import Api from '@/api'
import Vue from 'vue'

export default {
  name: 'Home',
  data(){
    return {
      topics:[],
      headTit:'话题列表'
    }
  },
  components: {
    BackBar
  },
  methods:{
      go(id){
        this.$router.push('/topic/'+id)
      },
    refreshPost(){
      var that=this
      Api.go('all_topic',false,(res)=>{
        Vue.set(that,'topics',res)
      }) 
    }
  },
  mounted(){
    this.refreshPost()
  }
}
</script>

<style lang="less" scoped>
    .all-topics{
        background: #fff;
        .topic{
            height: .8rem;
            line-height: .8rem;
            display: block;
            padding:0 0.32rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                display: flex;
                align-items: center;
                font-size: .28rem;
                color: #333;
            }
            img{
                width: .32rem;
                height: .32rem;
                margin-right: .16rem;
            }
            i{
                display: inline-block;
                width: .32rem;
                height: .32rem;
                background:url(~@/assets/img/jt_fr.png) no-repeat center center /100% auto;  
            }
        }
    }
//     .all-topics .topic{height: 1rem;line-height: 1rem;display: block;padding:0 0.2rem;border-bottom: 1px solid #ccc;}
// .all-topics .topic i{float: right;width: 0.32rem;display: inline-block;}
// .all-topics .topic span{float: right;}
</style>

